// apiUtils.js
import axios from "axios";

const BASE_URL =
  "https://zwpkkub3s8.execute-api.ap-south-1.amazonaws.com/default/";

export const sendOtp = async (mobileNumber) => {
  try {
    const payload = JSON.stringify({
      phoneNumber: "91" + mobileNumber,
      broker: "acagarwal",
      orderId: null,
      otp: null,
    });

    const response = await axios.post(`${BASE_URL}og_sendverify_otp`, payload, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
    throw error;
  }
};

export const verifyOtp = async (mobileNumber, orderId, otp) => {
  try {
    const payload = JSON.stringify({
      phoneNumber: "91" + mobileNumber,
      broker: "acagarwal",
      orderId: orderId,
      otp: otp,
    });

    const response = await axios.post(`${BASE_URL}og_sendverify_otp`, payload, {
      headers: { "Content-Type": "application/json" },
    });

    console.log("Response from Step 3:", response);

    // for step 4
    const { user_id, new_user } = response.data;

    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error;
  }
};

export const resendOtp = async (mobileNumber, orderId) => {
  try {
    const payload = JSON.stringify({
      phoneNumber: "91" + mobileNumber,
      broker: "acagarwal",
      orderId: orderId,
      otp: null,
    });

    const response = await axios.post(`${BASE_URL}og_sendverify_otp`, payload, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    console.error("Error resending OTP:", error);
    throw error;
  }
};

export const connectToDashboard = async (payload) => {
  try {
    const response = await axios.post(
      `https://cgcxpd7eql.execute-api.ap-south-1.amazonaws.com/default/og_acagarwal_token_generation`,
      JSON.stringify(payload),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    console.log("Response from Step 4:", response);

    // saving in ls
    // console.log(response.data);
    const { access_token, ws_token, client_id } = response.data;
    Object.keys({ access_token, ws_token, client_id }).forEach((key) => {
      localStorage.setItem(key, response.data[key]);
    });

    return response.data;
  } catch (error) {
    console.error("Error connecting to dashboard:", error);
    throw error;
  }
};
