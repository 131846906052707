import { useState, useEffect } from "react";
import axios from "axios";

const useAuthPopup = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const authLogin = async (authCode) => {
    try {
      const response = await axios.post(
        "https://6erz7gfw7j.execute-api.ap-south-1.amazonaws.com/default/fyersLoginOptionGeeks",
        {
          auth_code: authCode,
        }
      );
      const data = JSON.parse(response.data.body);
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("client_id", data.client_id);
      localStorage.setItem("ws_token", data.ws_token);
      window.location.replace(
        "https://app.optiongeeks.in?access_token=" +
          data.access_token +
          "&client_id=" +
          data.client_id +
          "&ws_token=" +
          data.ws_token
      );
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const authCode = urlParams.get("auth_code");
  //   debugger
  //   if (authCode) {
  //     authLogin(authCode);
  //   } else {
  //     console.log("Auth code not found.");
  //   }
  // }, []);

  return {
    isPopupOpen,
    handleOpenPopup,
    handleClosePopup,
  };
};

export default useAuthPopup;
