import { Route, Routes } from "react-router";
import Main from "./Screens/Main";
import Footer from "./components/Footer";
import Header from "./components/Header";
import TermsAndConditions from "./Screens/TermsAndConditions";
import Privacy from "./Screens/Privacy";
import { useEffect, useState } from "react";
import MainMobile from "./Screens/MainMobile";
import errorIcon from "./images/error_icon.svg";
import CustomToastContent from "./components/CustomToastContent";

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 420);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 420);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("runnig....");

    const params = new URLSearchParams(window.location.search);
    const authCode = params.get("authCode");
    const userId = params.get("userId");

    if (authCode && userId) {
      sendRequestToBackend(authCode, userId);
    } else {
      console.log("error");
    }
  }, [window.location.href]);

  const sendRequestToBackend = async (authCode, userId) => {
    try {
      const payload = {
        broker: "aliceblue",
        client_id: userId,
        authCode: authCode,
      };

      const response = await fetch(
        "https://f8l3dzypp6.execute-api.ap-south-1.amazonaws.com/default/og_auth_generation",
        {
          method: "POST",
          body: JSON.stringify(payload),
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status) {
          const { access_token, ws_token, client_id, user_id } = responseData;
          window.location.href = `https://app.optiongeeks.in/auth_user?s=ok&code=200&access_token=${access_token}&ws_token=${ws_token}&client_id=${client_id}&user_id=${user_id}&broker=aliceblue`;
        } else {
          // toast.error(
          //   <CustomToastContent
          //     heading="Failed to cancel orders!"
          //     message={response.data.message}
          //   />,
          //   {
          //     className: "custom-error-toast",
          //     icon: <img src={errorIcon} alt="Success Icon" />,
          //   }
          // );
          console.log("error");
        }
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error handling response:", error);
    }
  };

  return (
    <>
      <Header />
      <Routes>
        {isMobile ? (
          <Route path="/" element={<MainMobile />} />
        ) : (
          <Route path="/" element={<Main />} />
        )}
        <Route path="/t&c" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<Privacy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
