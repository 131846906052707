import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
const OTPForm = ({
  handleBack,
  handleNext,
  onClose,
  error,
  handleResendOtp,
  successMessage,
}) => {
  const [otp, setOtp] = useState("");
  const handleChange = (otp) => setOtp(otp);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Trigger the Next button click when Enter is pressed
      handleNext(otp);
    }
  };
  return (
    <div className="auth-popup d-flex flex-column">
      <div className="d-flex justify-content-between">
        <div
          className="d-flex align-items-center justify-content-center gap-2"
          onClick={handleBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            type="button">
            <path
              d="M6.59756 1L1 6.83333M1 6.83333L6.59756 12.6667M1 6.83333L15.1666 6.85978"
              stroke="#1E2028"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          <span className="auth-popup-title">Continue with Mobile</span>
        </div>
        <span onClick={onClose} type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            style={{ marginLeft: "auto" }}>
            <path
              d="M1 1.00002L7.38709 7.38711M7.38709 7.38711L13.7742 13.7742M7.38709 7.38711L1 13.7742M7.38709 7.38711L13.7742 1"
              stroke="#1E2028"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="auth-popup-subtitle">Please enter 6 digit OTP</div>

      <div className="d-flex justify-content-center align-items-center">
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          renderInput={(props) => (
            <input {...props} onKeyDown={handleKeyPress} />
          )}
          inputStyle={{
            border: "1px solid #989898",
            borderRadius: "4px",
            width: "40px",
            height: "46px",
            fontSize: "14px",
            color: "#000",
            fontWeight: "500",
            caretColor: "#000",
            marginTop: "46px",
            marginRight: "10px",
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />
      </div>
      {error && (
        <div className="d-flex justify-content-center mt-2 error-message">
          {error}
          <span className="ms-2 text-primary" onClick={handleResendOtp}>
            <Link>Resend OTP</Link>
          </span>
        </div>
      )}

      {successMessage && (
        <div className="d-flex justify-content-center mt-2 success-message">
          {successMessage}
        </div>
      )}

      <div className="d-flex justify-content-center mt-3">
        <button className="auth-form-button" onClick={() => handleNext(otp)}>
          Next
        </button>
      </div>
    </div>
  );
};

export default OTPForm;
