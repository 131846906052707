import React, { useState } from "react";

const MobileForm = ({
  handleBack,
  handleNext,
  onClose,
  validateMobileNumber,
}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const handleInputChange = (e) => {
    setMobileNumber(e.target.value);
    setError("");
  };

  const handleNextClick = () => {
    const isValidMobile = validateMobileNumber(mobileNumber);

    if (isValidMobile) {
      handleNext(mobileNumber);
    } else {
      setError("Please enter a valid 10 digit mobile number");
    }
  };
  // Triggering the Get OTP button when Enter is pressed
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNextClick();
    }
  };
  return (
    <div className="auth-popup d-flex flex-column">
      <div className="d-flex justify-content-between">
        <div
          className="d-flex align-items-center justify-content-center gap-2"
          onClick={handleBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            type="button">
            <path
              d="M6.59756 1L1 6.83333M1 6.83333L6.59756 12.6667M1 6.83333L15.1666 6.85978"
              stroke="#1E2028"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          <span className="auth-popup-title">Continue with Mobile</span>
        </div>
        <span onClick={onClose} type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            style={{ marginLeft: "auto" }}>
            <path
              d="M1 1.00002L7.38709 7.38711M7.38709 7.38711L13.7742 13.7742M7.38709 7.38711L1 13.7742M7.38709 7.38711L13.7742 1"
              stroke="#1E2028"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="auth-popup-subtitle">
        Please provide your mobile number
      </div>

      <input
        className="mobile-number-input "
        type="tel"
        placeholder="Mobile Number"
        value={mobileNumber}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        required
      />
      {error && (
        <div className="d-flex justify-content-center mt-2 error-message">
          {error}
        </div>
      )}
      <div className="d-flex justify-content-center mt-3">
        <button className="auth-form-button" onClick={handleNextClick}>
          Get OTP
        </button>
      </div>
    </div>
  );
};

export default MobileForm;
