import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/newlogo.svg";

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: "#E7E8FF" }}>
      <div className="grid-container-footer">
        <div className="inner-footer d-flex justify-content-between py-4">
          <div className="d-flex flex-column gap-3 w-50 w-md-100">
            <Link to="/">
              <img src={logo} alt="logo" className="footer-logo" />
            </Link>
            <div className="footer-text">
              Product of Infitout Financial Technologies Pvt. Ltd.
            </div>
            <div className="d-flex gap-2 gap-md-3 flex-column flex-md-row">
              <Link className="footer-text" target="_blank"
               to="https://twitter.com/OptionGeeksHQ">Twitter</Link>
              <Link className="footer-text" target="_blank"
              to="https://www.linkedin.com/company/optiongeekshq/">LinkedIn</Link>
              {/* <Link className="footer-text" 
              >Instagram</Link> */}
            </div>
            <div className="footer-text footer-link-end">
              Charts are powered by <a target="__blank"  className="footer-text footer-link-end" 
              href="https://www.tradingview.com/">
                TradingView
                </a>
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <div className="footer-mail mb-5">hello@optiongeeks.in</div>
            <Link className="footer-text footer-link-end" to="/t&c">
              Terms & Conditions
            </Link>
            <Link className="footer-text footer-link-end" to="privacypolicy">
              Privacy policy
            </Link>
          </div>
        </div>
      </div>
      <div className="text-center footer-mail">© All rights reserved 2024</div>
    </footer>
  );
};

export default Footer;
