import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../images/newlogo.svg";
import MultiStepForm from "./MultiStepForm";
import useAuthPopup from "./useAuthPopup"; // Import the custom hook

const Header = () => {
  const { isPopupOpen, handleOpenPopup, handleClosePopup } = useAuthPopup();

  return (
    <>
      <div className="navbar navbar-expand-lg bg-body-white bg-white shadow-sm fixed-top">
        {isPopupOpen && <div className="popup-backdrop"></div>}
        <div className="container-fluid px-3 px-md-5 pe-lg-4 ps-lg-4 bg-white">
          {/* <NavLink className="navbar-brand" to="/"> */}
          <img src={Logo} alt="logo" />
          {/* </NavLink> */}
          <div className="d-flex align-items-center gap-3">
            <button
              className="login-btn btn fw-medium"
              onClick={handleOpenPopup}>
              Continue with broker
            </button>
          </div>
        </div>
      </div>
      {isPopupOpen && <MultiStepForm onClose={handleClosePopup} />}
    </>
  );
};

export default Header;
