import React, { useState } from "react";
import SelectBroker from "./SelectBroker";
import MobileForm from "./MobileNoForm";
import OTPForm from "./OTPForm";
import APIForm from "./APIForm";
import { sendOtp, verifyOtp, resendOtp, connectToDashboard } from "../api";
const MultiStepForm = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [orderId, setOrderId] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [mobileNumberForVerification, setMobileNumberForVerification] =
    useState("");
  const [selectedBroker, setSelectedBroker] = useState("");
  const [user_id, setUser_id] = useState("");
  const [new_user, setNew_user] = useState(null);

  const [details, setDetails] = useState({
    interactive_appkey: "",
    interactive_secret: "",
    market_appkey: "",
    market_secret: "",
    user_id: "",
    new_user: null,
  });

  const handleBrokerSelect = (selectedBroker) => {
    setSelectedBroker(selectedBroker);
    console.log("broker", selectedBroker);
    if (selectedBroker === "aliceblue") {
      window.location.href =
        "https://ant.aliceblueonline.com/?appcode=AzRdbTzgXChUgEZ";
    } else {
      setStep(2);
    }
  };
  const handleBack = () => {
    setStep(step - 1);
  };

  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobileNumber);
  };

  const handleGetOtp = async (mobileNumber) => {
    if (validateMobileNumber(mobileNumber)) {
      try {
        const response = await sendOtp(mobileNumber);

        setOrderId(response.orderId);
        setMobileNumberForVerification(mobileNumber);
        setStep(3);
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
    }
  };

  const handleVerifyOtp = async (otp) => {
    try {
      const response = await verifyOtp(
        mobileNumberForVerification,
        orderId,
        otp
      );

      if (response.isOTPVerified) {
        setUser_id(response.user_id);
        setNew_user(response.new_user);
        setStep(4);

        // Update details
        setDetails({
          ...details,
          user_id: response.user_id,
          new_user: response.new_user,
        });

        if (!response.new_user) {
          // Redirecting to optiongeeks if new_user is false
          const access_token = response.access_token;
          const ws_token = response.ws_token;
          const client_id = response.client_id;
          const user_id = response.user_id;
          const broker = selectedBroker;
          window.location.href = `https://app.optiongeeks.in/auth_user?s=ok&code=200&access_token=${access_token}&ws_token=${ws_token}&client_id=${client_id}&user_id=${user_id}&broker=${broker}`;

          onClose();
        }
      } else {
        setError("Please enter the correct OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await resendOtp(mobileNumberForVerification, orderId);

      setSuccessMessage("OTP re-sent successfully!");
      setError("");

      // updating orderId
      if (response && response.orderId) {
        setOrderId(response.orderId);
      }
      // Clear success message after 5 sec
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const apiResponse = await connectToDashboard({
        ...details,
        user_id: user_id,
        new_user: new_user,
      });
      console.log("final step response", apiResponse);
      const { status, ...responseWithoutStatus } = apiResponse;

      const dataToSave = {
        broker_name: selectedBroker,
        user_id,
        ...responseWithoutStatus,
      };

      Object.keys(dataToSave).forEach((key) => {
        localStorage.setItem(key, dataToSave[key]);
      });

      const access_token = apiResponse.access_token;
      const ws_token = apiResponse.ws_token;
      const client_id = apiResponse.client_id;
      // const user_id = apiResponse.user_id;
      const broker = selectedBroker;
      window.location.href = `https://app.optiongeeks.in/auth_user?s=ok&code=200&access_token=${access_token}&ws_token=${ws_token}&client_id=${client_id}&user_id=${user_id}&broker=${broker}`;

      onClose();
    } catch (error) {
      console.error("Error connecting to dashboard:", error);
    }
  };

  return (
    <div className="auth-popup d-flex flex-column">
      {/* broker select */}
      {step === 1 && (
        <div>
          <SelectBroker handleNext={handleBrokerSelect} onClose={onClose} />
        </div>
      )}

      {/* Enter mobile no */}
      {step === 2 && (
        <div>
          <MobileForm
            handleBack={handleBack}
            handleNext={handleGetOtp}
            onClose={onClose}
            validateMobileNumber={validateMobileNumber}
          />
        </div>
      )}

      {/* Enter otp */}
      {step === 3 && (
        <div>
          <OTPForm
            handleNext={handleVerifyOtp}
            handleBack={handleBack}
            onClose={onClose}
            error={error}
            handleResendOtp={handleResendOtp}
            successMessage={successMessage}
          />
        </div>
      )}

      {/* Enter api details */}
      {step === 4 && (
        <div>
          <APIForm
            details={details}
            setDetails={setDetails}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
