import React from "react";
import Hero_Image from "../images/hero_image.svg";
import "../css/LandingPage.css";
import useAuthPopup from "./useAuthPopup";
import MultiStepForm from "./MultiStepForm";
const Banner = () => {
  const { isPopupOpen, handleOpenPopup, handleClosePopup } = useAuthPopup();
  return (
    <>
      <div className="grid-container " style={{"marginTop": "176px"}}>
        {isPopupOpen && <div className="popup-backdrop"></div>}
        <div className="banner-left">
          <div>
            <h1>
              All in one tool for <span className="exclusive">F&O</span> traders
            </h1>
            <p style={{ fontSize: "24px", fontWeight: "500" }}>
              Strategy creator + Option chain + Iceberg orders + Scalp orders +
              Position analytics
            </p>
          </div>
          <div>
            <button
              className="custom-width login-btn btn fw-medium"
              onClick={handleOpenPopup}>
              Continue with broker
            </button>
            <div className="btn-text mt-2">
              Login directly with your broker accounts
            </div>
          </div>
        </div>
        <div className="banner-right">
          <img
            className=" hero_image"
            src={Hero_Image}
            style={{ width: "100%" }}
            alt="banner for the portal"
          />
        </div>
      </div>
      {isPopupOpen && <MultiStepForm onClose={handleClosePopup} />}
    </>
  );
};

export default Banner;
