import React from "react";

const Privacy = () => {
  return (
    <div className="grid-container">
      <div style={{ gridColumn: "span 12" }}>
        <h1>Privacy Policy</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis ut
          veritatis blanditiis voluptatum perferendis dolorum culpa officiis
          placeat vel nobis deleniti iure minus laboriosam, iusto, eaque facere
          reprehenderit, quibusdam officia earum consequatur. Adipisci
          reiciendis, cumque laboriosam consectetur explicabo officiis, nam
          praesentium est sapiente, debitis nesciunt. Inventore ab dolore optio
          veritatis illum? Et natus laudantium quod quaerat eum obcaecati
          expedita dolorum.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
