import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="grid-container">
      <div style={{ gridColumn: "span 12" }}>
        <h1>Terms & Conditions</h1>
        1. Acceptance of Terms

By accessing and using the website [optiongeeks.in], you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use the website.

2. Use of the Website

You agree to use the OptionGeeks website only for lawful purposes and in a manner consistent with all applicable laws and regulations. You may not use the website in any manner that could damage, disable, overburden, or impair the website or interfere with any other party's use and enjoyment of it.

3. Intellectual Property

All content on the OptionGeeks website, including but not limited to text, images, graphics, logos, and software, is the property of Infitout Financial Technologies Pvt Ltd. and is protected by intellectual property laws. You may not reproduce, distribute, or display any portion of the website without the prior written consent of Infitout Financial Technologies Pvt Ltd.

4. Refund Policy

No refund will be provided once payment is made on the OptionGeeks website.

5. Disclaimer of Warranties

The OptionGeeks website is provided "as is" without any representations or warranties, express or implied. Infitout Financial Technologies Pvt Ltd. makes no representations or warranties in relation to this website or the information and materials provided.

6. Trading Disclaimer

Infitout Financial Technologies Pvt Ltd. is not responsible for any unforeseen market events, freak trades, or issues that may arise with broker APIs. Users are advised to exercise caution and conduct their own due diligence when engaging in trading activities.

7. Third-Party Links

The OptionGeeks website may contain links to third-party websites. Infitout Financial Technologies Pvt Ltd. is not responsible for the content or privacy practices of these websites. The inclusion of any link does not imply endorsement by Infitout Financial Technologies Pvt Ltd.
      </div>
    </div>
  );
};

export default TermsAndConditions;
