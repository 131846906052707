import React from "react";
import LOGO from "../images/Ac_agarwal 2.png";
import { Link } from "react-router-dom";

const APIForm = ({ details, setDetails, handleSubmit }) => {
  return (
    <div>
      <div className="auth-popup auth-popup-api d-flex flex-column">
        <div className="d-flex gap-2">
          <img src={LOGO} alt="logo" className="ac-agarwal-logo" />
          <div className="d-flex-flex-column gap-2">
            <div className="boker-name-api-form">AC Agarwal</div>
            <div className="connect-your-acount">Connect your account</div>
          </div>
        </div>
        <div className="form-api-type">Interactive Order API</div>
        <input
          className="api-input"
          type="text"
          placeholder="API Key"
          value={details.interactive_appkey}
          onChange={(e) =>
            setDetails({ ...details, interactive_appkey: e.target.value })
          }
        />
        <input
          className="api-input"
          type="text"
          placeholder="API Secret"
          value={details.interactive_secret}
          onChange={(e) =>
            setDetails({ ...details, interactive_secret: e.target.value })
          }
        />

        <div className="form-api-type">Market Data API</div>
        <input
          className="api-input"
          type="text"
          placeholder="API Key"
          value={details.market_appkey}
          onChange={(e) =>
            setDetails({ ...details, market_appkey: e.target.value })
          }
        />
        <input
          className="api-input"
          type="text"
          placeholder="API Secret"
          value={details.market_secret}
          onChange={(e) =>
            setDetails({ ...details, market_secret: e.target.value })
          }
        />

        <Link
          className="text-center generate-api-link"
          target="_blank"
          to="https://symphony.acagarwal.com:3000/dashboard#!/login">
          Generate API Keys
        </Link>

        <div className="d-flex justify-content-center mt-3">
          <button
            className="auth-form-button connect-dash-btn"
            onClick={handleSubmit}>
            Connect & Go to dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default APIForm;
