import React from "react";
import AC_AGARWAL_LOGO from "../images/Ac_agarwal 1.png";
import ALICE_BLUE_LOGO from "../images/aliceblue_logo.jpeg";

const SelectBroker = ({ onClose, handleNext }) => {
  const brokers = ["acagarwal", "aliceblue"];

  const getBrokerLogo = (broker) => {
    switch (broker) {
      case "acagarwal":
        return AC_AGARWAL_LOGO;

      case "aliceblue":
        return ALICE_BLUE_LOGO;

      default:
        return null;
    }
  };

  return (
    <div className="auth-popup d-flex flex-column">
      <div className="d-flex justify-content-between">
        <span className="auth-popup-title">Select your broker</span>
        <span onClick={onClose} type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            style={{ marginLeft: "auto" }}>
            <path
              d="M1 1.00002L7.38709 7.38711M7.38709 7.38711L13.7742 13.7742M7.38709 7.38711L1 13.7742M7.38709 7.38711L13.7742 1"
              stroke="#1E2028"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="auth-popup-subtitle">
        Please select your broker to continue
      </div>

      <div className="d-flex gap-3 broker-logo-container" type="button">
        {brokers.map((broker, index) => (
          <div
            key={index}
            className="broker-logo d-flex flex-column justify-content-center align-items-center"
            onClick={() => handleNext(broker)}>
            <img
              src={getBrokerLogo(broker)}
              alt={`${broker} logo`}
              width={27}
              height={30}
            />
            <div className="broker-name">{broker}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectBroker;
